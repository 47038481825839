<template>
  <section class="activation-lethality">
    <div class="container med">
      <div class="lines right">
        <span></span>
      </div>
      <div class="al-wrapper">
        <div class="page-indicator" v-if="data.activation_lethality_indicator_toggle">
          <div class="indicator-letter">{{ data.activation_lethality_indicator_letter }}</div>
          <div class="indicator-title text-smaller">{{ data.activation_lethality_indicator_title }}</div>
        </div>
        <div class="al-content">
          <div class="headline h3" v-html="data.activation_lethality_headline"></div>
          <div class="text editor-formatting" v-html="data.activation_lethality_text"></div>
        </div>
        <div class="al-quadrants">
          <div class="x-title h4">Selection Pressure</div>
          <div class="y-title h4">Mutation's<br> Biological Effects:</div>
          <div class="quadrants" ref="trigger">
            <div class="quadrant" :class="{ 'gradient': quad.activation_lethality_quad_gradient }" v-for=" (quad, index)
              in data.activation_lethality_quads" :key="index">
              <div class="quad-title-wrapper">
                <span v-if="quad.activation_lethality_quad_gradient"></span>
                <div class="title h4" v-html="quad.activation_lethality_quad"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "ActivationLethalityComponent",
    "CLASSNAME": "activation-lethality",
    "URL": "http://local.delphiatx.com/"
   }
  #ENDMETA */
import { ref, onBeforeUnmount, onMounted } from 'vue'
import { gsap, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const trigger = ref(null)

let tl

onMounted(() => {

  tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top+=100 bottom',
      end: '+=500',
      scrub: true,
    },
  })
  .to('.quadrant.gradient', {
    borderColor: '#DF1A1A',
    ease: Linear.easeNone,
  })
  .to('.quadrant.gradient .title', {
    color: '#ffffff',
    ease: Linear.easeNone,
  }, 0)
  .to('.quadrant.gradient span', {
    opacity: 1,
    ease: Linear.easeNone,
  }, 0)
})

onBeforeUnmount(() => {
  tl.kill()
})
</script>

<style lang="scss">
.activation-lethality {
  padding: 200px 0 100px;
  overflow: hidden;

  @include mobile {
    padding: 100px 0 40px;
  }

  .lines {
    position: absolute;
    top: -200px;
    z-index: 0;

    @include mobile {
      top: -100px;
    }

    &.right {
      right: -30px;

      &:before {
        right: 30px;
      }

      &:after {
        right: 0;
      }

      span {
        right: 27px;
      }
    }

    &:before,
    &:after,
    span {
      position: absolute;
      top: 0;
      display: block;
    }

    &:before,
    &:after {
      content: '';
      background: rgba(0, 0, 0, 0.20);
    }

    &:before {
      width: 1px;
      height: 120px;
    }

    &:after {
      top: 50px;
      width: 373px;
      height: 1px;
    }

    span {
      top: 47px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      z-index: 1;
      border: 1px solid $black;
    }
  }

  .al-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet-landscape(1) {
      flex-direction: column;
    }

    .page-indicator {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;

      @media only screen and (min-width: 1440px) {
        left: -87px;
      }

      @media only screen and (max-width: 1100px) {
        top: -40px;
      }

      @include  tablet-landscape(1) {
        position: relative;
        top: 0;
        left: 0;
        align-self: flex-start;
        margin: 0 0 40px;
      }

      .indicator-letter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        margin-right: 17px;
        border-radius: 50%;
        font-size: 2.8rem;
        background: linear-gradient(90deg, #FFA308 -13.76%, #FF5F06 45.07%, #DF1A1A 99.99%);
        color: $white;

        @include mobile {
          width: 50px;
          height: 50px;
          font-size: 2rem;
        }
      }
    }

    .al-content {
      flex: 1;
      max-width: 519px;
      margin-right: 40px;

      @include tablet-landscape(1) {
        flex-direction: column;
        max-width: 100%;
        margin: 0 0 40px;
      }

      .headline {
        margin: 0 0 30px;
      }
    }

    .al-quadrants {
      position: relative;
      padding-top: 100px;
      padding-left: 100px;

      @include mobile {
        width: 100%;
        padding-top: 65px;
        padding-left: 65px;
      }

      .x-title,
      .y-title {
        top: 0;
        position: absolute;
        text-align: center;
      }

      .x-title {
        width: calc(100% - 100px);

        @include mobile {
          width: calc(100% - 65px);
        }
      }

      .y-title {
        width: auto;
        height: auto;
        transform: translateY(-50);
        transform: translateY(-50%) rotate(-90deg);
        top: 55%;
        left: -100px;

        @include mobile {
          top: 60%;
          left: -132px;

          br {
            display: none;
          }
        }
      }

      .quadrants {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 492px;

        @include mobile {
          width: 100%;
        }

        .quadrant {
          position: relative;
          width: calc(50% - 12px);
          height: 0;
          padding-bottom: calc(50% - 16px);
          border: 2px solid #E2E2E2;

          @include mobile {
            width: calc(50% - 6px);
            padding-bottom: calc(50% - 10px);
          }

          &:before,
          &:after {
            position: absolute;
            display: block;
            font-size: 1.8rem;
            text-align: center;
          }

          &:first-child,
          &:nth-child(2) {
            margin-bottom: 24px;

            @include mobile {
              margin-bottom: 12px;
            }

            &:before {
              top: -25px;
              width: 100%;
              transform: translateY(-100%);

              @include mobile {
                top: -10px;
              }
            }
          }

          &:first-child,
          &:nth-child(3) {

            &:after {
              top: 0;
              left: -65%;
              width: 100%;
              transform: rotate(-90deg) translateX(-45%);
            }
          }

          &:first-child {  

            &:before {
              content: 'Positive';
            }

            &:after {
              content: 'Negative';

            }
          }

          &:nth-child(2) {

            &:before {
              content: 'Negative';
            }
          }

          &:nth-child(3) {

            &:after {
              content: 'Positive';
            }
          }

          .quad-title-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            text-align: center;
            z-index: 1;

            .title {
              position: relative;
            }
          }

          span {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-image: url('@/assets/img/gradient-quad.png');
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 0;
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>
